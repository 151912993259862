<template>
  <b-container fluid>
<!--    <iframe src="https://books.zoho.com/app/826343491#/home/dashboard"></iframe>-->
    <b-row>
      <b-col lg="12">
        <iq-card v-if="hasPer('franchise.tab')">
          <template v-slot:headerTitle>
            <h4 class="card-title">Franchise</h4>
          </template>
          <template v-slot:body>
            <div v-if="loadTable" class="text-center">
              <spinner-loading text = 'Loading' />
            </div>
            <b-table v-else striped responsive  class="mb-0 table-borderless" :fields="field" :items="allFranchisers">
            </b-table>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
// import axios from 'axios'
export default {

  name: 'franchies',
  mounted () {
    core.index()
  },
  // created () {
  //   this.loadTable = true
  //   axios.get('https://max-gym.herokuapp.com/api/investor').then(res => {
  //     // console.log(res.data)
  //     this.allFranchisers = res.data
  //     this.loadTable = false
  //   })
  // },
  data () {
    return {
      loadTable: false,
      allFranchisers:
        [{
          _id: {
            $oid: '6194da6063fd0500046ca1b5'
          },
          user_name: 'gamal eldin mohamed',
          job: 'engineer',
          place_address: 'no',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01225501335',
          venue: '0000',
          place_to_invest: false,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Cairo',
          details: 'no details for activity type  and building type '
        }, {
          _id: {
            $oid: '6194dc3963fd0500046ca1b6'
          },
          user_name: 'mohamed mosaad ',
          job: 'engineer ',
          place_address: "eltagamo'",
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01001648680',
          venue: '000',
          place_to_invest: false,
          activity_type: 'Administrative',
          building_type: 'Mall',
          franchised_before: false,
          covernate: 'Cairo',
          details: 'عايز يعرف العائد بتاع الفلوس كام وقالتله هنحدد في الميتينج \nno details for activity type - building - franshised before'
        }, {
          _id: {
            $oid: '6194dd3b63fd0500046ca1b7'
          },
          user_name: 'edward nader ',
          job: 'purshasing specialist ',
          place_address: 'no',
          email: 'purchasing@minamark.com',
          mobile_number: '01223998860',
          venue: '00',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Hurghada',
          details: 'hotel \nwith his card'
        }, {
          _id: {
            $oid: '6194df7863fd0500046ca1b8'
          },
          user_name: 'ahmed sarhan ',
          job: 'ceo &founder ',
          place_address: 'مركز شباب نور المقطم بالهضبة الوسطى - المقطم ',
          email: 'firststep@yahoo.com',
          mobile_number: '0100588979',
          venue: '000',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Cairo',
          details: 'club '
        }, {
          _id: {
            $oid: '6194e09763fd0500046ca1b9'
          },
          user_name: 'ayman amer ',
          job: 'journalist ',
          place_address: 'no',
          email: 'ayman_amer_@hotmail.com',
          mobile_number: '01006662891',
          venue: '000',
          place_to_invest: false,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Cairo',
          details: 'عايز يستثمر في مصر - عايز ميتينج مع كابتن مينا \\عايش في كندا عربي مش مصري \nno data for activity . building type . frenshised before or not'
        }, {
          _id: {
            $oid: '6194e12763fd0500046ca1ba'
          },
          user_name: 'eslam saeed',
          job: 'developer ',
          place_address: 'masr el gdida',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01004943918',
          venue: '000',
          place_to_invest: false,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Cairo',
          details: 'no data for activity type - building - frenshised'
        }, {
          _id: {
            $oid: '6194e30163fd0500046ca1bb'
          },
          user_name: 'mostafa saeed',
          job: 'manger of bussiness loop company',
          place_address: 'el sadaat - el shaikh zayed',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01022363645',
          venue: '200000',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Mall',
          franchised_before: true,
          covernate: 'Cairo',
          details: 'متوسع في كذا محافظة \nبلد الغريب \nنوع النشاط تجاري - اداري - ترفيهي \nمساحة المكان : 200000-100000'
        }, {
          _id: {
            $oid: '6194e38363fd0500046ca1bc'
          },
          user_name: 'khaled hamdy sayed',
          job: 'manger',
          place_address: 'no',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '0109056666',
          venue: '0000',
          place_to_invest: false,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Cairo',
          details: 'no building type details -\nassigned by mina samir '
        }, {
          _id: {
            $oid: '6194e57563fd0500046ca1bd'
          },
          user_name: 'mariam roshdy ',
          job: 'designer ',
          place_address: 'el tagamoa el awl - new cairo',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01001253122',
          venue: '650',
          place_to_invest: true,
          activity_type: 'Administrative',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Cairo',
          details: 'VILLA \nfrinshised befroe - no data '
        }, {
          _id: {
            $oid: '6194e74f63fd0500046ca1be'
          },
          user_name: 'samir abd el menaim ',
          job: 'investor',
          place_address: 'العياط مركز البدرشين قطعة 84',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01204799969',
          venue: '4200',
          place_to_invest: true,
          activity_type: 'Residential',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Alexandria',
          details: 'مكان اخر : عمارة 8 - 6 اكتوبر - الحي التالت \nعنده فدان في للبدرشين \nFranchised Before : no data'
        }, {
          _id: {
            $oid: '6194e87663fd0500046ca1bf'
          },
          user_name: 'sherief zohery ',
          job: 'journaist',
          place_address: 'el manial - abd el aziz al saoud ',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01115555124',
          venue: '300',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Cairo',
          details: 'حابب يأجر مش يستثمر \nاربع طوابق كل طابق 300 م '
        }, {
          _id: {
            $oid: '6194e94d63fd0500046ca1c0'
          },
          user_name: 'ali labib ',
          job: 'ceo',
          place_address: 'no',
          email: 'ali@labib-group.com',
          mobile_number: '01111104993',
          venue: '000',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Cairo',
          details: 'no details \njust business card - assigned by dr,mirna'
        }, {
          _id: {
            $oid: '6194f1cf366f19000410ea48'
          },
          user_name: 'ahmed ibraheem ',
          job: 'real state company',
          place_address: 'dokki - mohandisin ',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01143076076',
          venue: '500',
          place_to_invest: false,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Giza',
          details: ''
        }, {
          _id: {
            $oid: '6194f290366f19000410ea49'
          },
          user_name: 'mohamed atef',
          job: 'owner marketing and advertising company ',
          place_address: 'no',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01020481118',
          venue: '500',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Mall',
          franchised_before: false,
          covernate: 'Cairo',
          details: 'tagamoa - sherouk - oubor \n+500 m'
        }, {
          _id: {
            $oid: '6194f310366f19000410ea4a'
          },
          user_name: 'amr melegy ',
          job: 'hr company',
          place_address: 'no',
          email: 'amelegy@benchmark-hr.com',
          mobile_number: '01277358888',
          venue: '0000',
          place_to_invest: false,
          activity_type: 'Commercial',
          building_type: 'Mall',
          franchised_before: true,
          covernate: 'Cairo',
          details: 'no data for  activity type - building - partner type '
        }, {
          _id: {
            $oid: '6194f3a4366f19000410ea4b'
          },
          user_name: 'tarek ahmed ezz',
          job: 'عضو مجلس ادارة  في شركة انشاءات وعقارات ',
          place_address: 'راس البر فندق اللسيان',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01014092464',
          venue: '10000',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Giza',
          details: '10000 متر هتتقسم بين الجيم وبراندات تانية '
        }, {
          _id: {
            $oid: '6194f437366f19000410ea4c'
          },
          user_name: 'sayed hassan ',
          job: 'journalist ',
          place_address: '6 اكتوبر المحور المركزي ',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01222920546',
          venue: '500',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Mall',
          franchised_before: false,
          covernate: 'Cairo',
          details: '500 متر ممكن تبقى اكبر عشان الول بتاعه'
        }, {
          _id: {
            $oid: '6194f56a366f19000410ea4d'
          },
          user_name: 'goerge gamil',
          job: 'freelancer',
          place_address: 'شارع الظاهر رمسيس الفجالة',
          email: 'info@ceramic-karras.com',
          mobile_number: '01224580056',
          venue: '400',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Alexandria',
          details: 'ارض كاملة فاضية دور ارضي \nعايز يعرف العائد اكتر والفلوس اللي محتاجينها \nمهتم جدا وبيقول نتابعه واتساب لو ماردش \n-01222135732 رقم والده'
        }, {
          _id: {
            $oid: '6194f6cb366f19000410ea4e'
          },
          user_name: 'ahmed elkhatieb ',
          job: 'doctor',
          place_address: 'elhai el sabeea / eltagmoa',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01098968466',
          venue: '600',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Cairo',
          details: 'villa +600 m'
        }, {
          _id: {
            $oid: '6194f7c7366f19000410ea4f'
          },
          user_name: 'mohamed fares',
          job: 'شركة الفارس للتصدير ',
          place_address: 'شارع 9 المعادي --- مدينة نصر مكرم عبيد',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01062709040',
          venue: '600',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Cairo',
          details: '400متر - 600 متر '
        }, {
          _id: {
            $oid: '6194f8c9366f19000410ea50'
          },
          user_name: 'mahmoud el hosary ',
          job: 'finance manager ',
          place_address: 'no',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01119990954',
          venue: '0000',
          place_to_invest: false,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Alexandria',
          details: 'استثمار في مكتب ايجار عربيات وحابب يعرف تفاصيل اكتر ويفتح لينا فرنشايز - متابعنا سوشيال ميديا '
        }, {
          _id: {
            $oid: '6194f927366f19000410ea51'
          },
          user_name: 'ibraheem saad ',
          job: 'engineer',
          place_address: 'شبين الكوم',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01228323116',
          venue: '000',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Zagazig',
          details: ''
        }, {
          _id: {
            $oid: '6194fa26366f19000410ea52'
          },
          user_name: 'islam adel ',
          job: 'no',
          place_address: 'no',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01008439774',
          venue: '40',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Faiyum',
          details: ''
        }, {
          _id: {
            $oid: '6194fb28366f19000410ea53'
          },
          user_name: 'kareem hamdy',
          job: 'pharmacist ',
          place_address: 'لطف الله - الفيوم',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01003572868',
          venue: '1000',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Mall',
          franchised_before: false,
          covernate: 'Faiyum',
          details: 'عنده مول 1000 متر ومكان 350 متر'
        }, {
          _id: {
            $oid: '6194fbe4366f19000410ea54'
          },
          user_name: 'abd elrahaman',
          job: 'executive director ',
          place_address: 'no',
          email: 'executive.director@evotech-co.com',
          mobile_number: '01066615530',
          venue: '0000',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Cairo',
          details: 'no data for Activity Type & building'
        }, {
          _id: {
            $oid: '6194fc4e366f19000410ea55'
          },
          user_name: 'mohamed gamal elsayed',
          job: 'مستورد قطع غيار',
          place_address: 'التجمع  الخامس - شارع مجمع البنوك',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01063624542',
          venue: '500',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Mall',
          franchised_before: true,
          covernate: 'Cairo',
          details: 'عنده اماكن كتير غير التجمع - محتاج يعرف ايه اللي يناسبنا'
        }, {
          _id: {
            $oid: '6194fcff366f19000410ea56'
          },
          user_name: 'andrew tatossion ',
          job: 'property consultant',
          place_address: 'tagamooa - sahel',
          email: 'andrew.tatossion@ahaly.com',
          mobile_number: '01025385863',
          venue: '00',
          place_to_invest: false,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Cairo',
          details: 'no details'
        }, {
          _id: {
            $oid: '6194fe00366f19000410ea57'
          },
          user_name: 'mohamed el attar ',
          job: 'مدير الاستيراتيجية ومجموعة كادمار ',
          place_address: '9 محطة الرمل',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01009697076',
          venue: '100',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Alexandria',
          details: '01097456570'
        }, {
          _id: {
            $oid: '6194fe8d366f19000410ea58'
          },
          user_name: 'mohamed adel',
          job: 'مكتب توريدات وتوكيلات ',
          place_address: 'اسكندرية العاصمة بطاش',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01225477177',
          venue: '140',
          place_to_invest: true,
          activity_type: 'Residential',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Alexandria',
          details: 'ابن صاحب الملك \nالاستثمار بمبلغ 500 الف'
        }, {
          _id: {
            $oid: '6194ff30366f19000410ea59'
          },
          user_name: 'sherief rafaat',
          job: 'pharmacist ',
          place_address: 'شارع الجمهورية اسيوط',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01008552544',
          venue: '300',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Asyut',
          details: 'مهتم بفكرة جيم للسيدات - صيدلي وعنده صيدليات ومأجر مكان '
        }, {
          _id: {
            $oid: '6194ffff366f19000410ea5a'
          },
          user_name: 'mina samir',
          job: 'شركة اغذية ومشروبات رويال جروب ',
          place_address: 'no',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01558043383',
          venue: '400',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Mall',
          franchised_before: true,
          covernate: 'Minya',
          details: 'مطاعم \n01280493383'
        }, {
          _id: {
            $oid: '61950093366f19000410ea5b'
          },
          user_name: 'mahmoud el shafie',
          job: 'technical sale ',
          place_address: 'no',
          email: 'mahmoud@corpratestack.com',
          mobile_number: '01019450040',
          venue: '000',
          place_to_invest: false,
          activity_type: 'Commercial',
          building_type: 'Mall',
          franchised_before: false,
          covernate: 'Cairo',
          details: 'no details'
        }, {
          _id: {
            $oid: '6195010d366f19000410ea5c'
          },
          user_name: 'gamal nasr',
          job: 'owner EMG - استشاري مبيعات وتنفيذ',
          place_address: 'elaasema eledarya',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01006027921',
          venue: '750',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Mall',
          franchised_before: false,
          covernate: 'Cairo',
          details: ''
        }, {
          _id: {
            $oid: '61950208366f19000410ea5d'
          },
          user_name: 'farah hesham ',
          job: 'marketing specialist at EBNY company',
          place_address: 'sohag',
          email: 'fhesham@ebny.com',
          mobile_number: '01117891222',
          venue: '000',
          place_to_invest: true,
          activity_type: 'Administrative',
          building_type: 'Mall',
          franchised_before: true,
          covernate: 'Sohag',
          details: 'مهتمين جدا ب سوهاج \nوممكن التجمع الخامس\nوعندهم كل الاوبشنز - عايزين ميتينج'
        }, {
          _id: {
            $oid: '61950276366f19000410ea5e'
          },
          user_name: 'ahmed elshabrawy',
          job: 'accountant',
          place_address: 'zagazig - gamaa area',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01210440665',
          venue: '600',
          place_to_invest: true,
          activity_type: 'Residential',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Zagazig',
          details: ''
        }, {
          _id: {
            $oid: '61950301366f19000410ea5f'
          },
          user_name: 'abdelrahman sherief',
          job: 'منسق المبادرة الرئاسية حياة كريمة',
          place_address: 'فيصل 15 احمد ناصر',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01120465562',
          venue: '500',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Giza',
          details: ''
        }, {
          _id: {
            $oid: '61950476366f19000410ea60'
          },
          user_name: 'soryaa abdeazeem',
          job: 'صاحب شركة توريدات مواد بناء ',
          place_address: 'الهرم - ',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01141728844',
          venue: '00',
          place_to_invest: false,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Giza',
          details: 'بتفكر تغير النشاط '
        }, {
          _id: {
            $oid: '619504fe366f19000410ea61'
          },
          user_name: 'khaled osama ',
          job: 'engineer',
          place_address: 'no',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01122148737',
          venue: '000',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Cairo',
          details: 'اشتغل مع براندات تانية زي بازوكا وهارت اتاك \nسمسار ف هيقدر يوفرلنا اماكن مختلفة مع المستثمر'
        }, {
          _id: {
            $oid: '61950565366f19000410ea62'
          },
          user_name: 'taher ahmed',
          job: 'مسئول تسويق في وزارة التربية والتعليم ',
          place_address: 'العاصمة الادارية',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01222265317',
          venue: '00',
          place_to_invest: false,
          activity_type: 'Administrative',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Cairo',
          details: 'سمسار وسايب كارت صاحبة الشركة'
        }, {
          _id: {
            $oid: '619505b7366f19000410ea63'
          },
          user_name: 'asmaa abo bakr',
          job: 'sales account manager ',
          place_address: 'no',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01146611141',
          venue: '800',
          place_to_invest: true,
          activity_type: 'Administrative',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Giza',
          details: ''
        }, {
          _id: {
            $oid: '6195061c366f19000410ea64'
          },
          user_name: 'ahmed elryadi',
          job: 'شريان للاستثمار العقاري owner',
          place_address: 'elrehab',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01207107961',
          venue: '000',
          place_to_invest: true,
          activity_type: 'Residential',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Cairo',
          details: ''
        }, {
          _id: {
            $oid: '61950691366f19000410ea65'
          },
          user_name: 'mai el nashar ',
          job: 'دكتورة بشرية',
          place_address: 'elsherouk ',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01157756000',
          venue: '000',
          place_to_invest: true,
          activity_type: 'Residential',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Alexandria',
          details: ''
        }, {
          _id: {
            $oid: '619523f2a315480004dfeb1a'
          },
          user_name: 'ahmed el sharqawyy',
          job: 'general maneger ',
          place_address: 'no',
          email: 'ahmed.sharkawy@ceo4edu.net',
          mobile_number: '0100028368',
          venue: '000',
          place_to_invest: false,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Cairo',
          details: 'no details '
        }, {
          _id: {
            $oid: '61952922a315480004dfeb1b'
          },
          user_name: 'khaled gamal saleh',
          job: 'marketing & developing consultant ',
          place_address: 'بني سويف كورنيش النيل',
          email: 'khalidgamal474@gmail.com',
          mobile_number: '01011215920',
          venue: '800',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Mall',
          franchised_before: true,
          covernate: 'Beni Suef',
          details: 'تتعدى 800 متر'
        }, {
          _id: {
            $oid: '61952981a315480004dfeb1c'
          },
          user_name: 'ahmed shabana',
          job: 'pharmacist',
          place_address: 'no',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01158907577',
          venue: '00',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Giza',
          details: 'عايز يساهم بالفلوس ومقالش اي حاجة - عاوز يقعد مع الادارة '
        }, {
          _id: {
            $oid: '619529d8a315480004dfeb1d'
          },
          user_name: 'mohamed gamal ',
          job: 'استشاري تحاليل طبية',
          place_address: 'no',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01008663717',
          venue: '800',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Faiyum',
          details: ''
        }, {
          _id: {
            $oid: '61952a36a315480004dfeb1e'
          },
          user_name: 'lamiaa bahnasy ahmed',
          job: 'marketing and advertisement agency',
          place_address: 'شارع جمال عبد الناصر - الفيوم',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01033334428',
          venue: '800',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Faiyum',
          details: ''
        }, {
          _id: {
            $oid: '61952ad6a315480004dfeb1f'
          },
          user_name: 'batric goerge ',
          job: 'assistant of executive director ',
          place_address: 'nasr city --- maadi',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01118859339',
          venue: '000',
          place_to_invest: false,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Cairo',
          details: 'no details\nmeeting with the board '
        }, {
          _id: {
            $oid: '61953cf44fcbac0004eb765c'
          },
          user_name: 'Mohamed medhat',
          job: 'Ceo',
          place_address: 'No',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '010000006147',
          venue: '000',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Mall',
          franchised_before: false,
          covernate: 'Cairo',
          details: 'متارجت كذا مكان مش مكان محدد \nالتجمع..'
        }, {
          _id: {
            $oid: '61953fac4fcbac0004eb765d'
          },
          user_name: 'Mohamed ali',
          job: 'Lawyer',
          place_address: 'Sheried street',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01009770540',
          venue: '700',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Cairo',
          details: 'دور كامل مساحته 700'
        }, {
          _id: {
            $oid: '619541974fcbac0004eb765e'
          },
          user_name: 'Nour eldeen saeed',
          job: 'مستورد موارد غذائية',
          place_address: 'Tanta',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01222299984',
          venue: '800',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Tanta',
          details: 'Aشغال ف العقارات\nمن 800 ل اكتر'
        }, {
          _id: {
            $oid: '619542634fcbac0004eb765f'
          },
          user_name: 'Salah matar',
          job: 'مدير ادارة التطوير والتدريب شركة عامر جروب',
          place_address: 'No',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01110124578',
          venue: '800',
          place_to_invest: false,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Cairo',
          details: 'مستثمر في العلامة التجارية في مجال المطاعم'
        }, {
          _id: {
            $oid: '619542bf4fcbac0004eb7660'
          },
          user_name: 'Mohamed  ali',
          job: 'صاحب شركة دعاية واعلان',
          place_address: 'Cairo',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01272233133',
          venue: '750',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Mall',
          franchised_before: false,
          covernate: 'Cairo',
          details: ''
        }, {
          _id: {
            $oid: '619544144fcbac0004eb7661'
          },
          user_name: 'Mohamed el shahawy',
          job: 'محاسب فانوني',
          place_address: 'Tagamooa',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01000809991',
          venue: '000',
          place_to_invest: false,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Cairo',
          details: 'بداية من 2 مليون'
        }, {
          _id: {
            $oid: '619625f544c05a00040ff99e'
          },
          user_name: 'aly  ',
          job: 'sales manager ',
          place_address: 'nasr city - makram ebaid',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01068821666',
          venue: '2000',
          place_to_invest: true,
          activity_type: 'Administrative',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Cairo',
          details: 'VIP \nاستثمر في كذا حاجة تانية مش جيمات \nميتينج ضروري جداااا'
        }, {
          _id: {
            $oid: '61962694fd08a800040ab2ef'
          },
          user_name: 'ahmed elroumyy',
          job: 'marketing and sales manager ',
          place_address: 'no',
          email: 'ahmed.elroumy@jettco.net',
          mobile_number: '01113337581',
          venue: '000',
          place_to_invest: false,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Alexandria',
          details: 'محتاج يعرف لو جاب مساحة 1000 متر هيدفع قد ايه والديل هيبقى ايه - محتاج الادارة تتواصل معاه'
        }, {
          _id: {
            $oid: '61962743fd08a800040ab2f0'
          },
          user_name: 'mohamed shoukryy taha',
          job: 'مستشار',
          place_address: 'no',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01016154716',
          venue: '000',
          place_to_invest: false,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Cairo',
          details: 'مستثمر في بنك الاسكان والتعمير \nمستثمر فيcib - عنده اسهم \nمستثمر في فندق نايل ريتز \nمقالش اي تفاصيل ( كان بيشقط ) '
        }, {
          _id: {
            $oid: '61962929fd08a800040ab2f1'
          },
          user_name: 'mohamed anany',
          job: 'sales manager',
          place_address: 'اي مكان احنا عاوزينه',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01280008027',
          venue: '0000',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Cairo',
          details: 'VIP\nشركة استثمار عندهم 4 براندات كل فترة في استثمار جديد \n\n'
        }, {
          _id: {
            $oid: '619629d2fd08a800040ab2f2'
          },
          user_name: 'MOHAMED TAIE',
          job: 'Engineer ',
          place_address: 'mariotya - haram - ellebeny',
          email: 'engmohdtaie@gmail.com',
          mobile_number: '01093337339',
          venue: '3500',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Giza',
          details: 'villa\nمع الشركاء'
        }, {
          _id: {
            $oid: '61962b0efd08a800040ab2f3'
          },
          user_name: 'micheal adel',
          job: 'finance manager',
          place_address: 'مدينة السادات المنوفية ',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01270514051',
          venue: '000',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Cairo',
          details: 'وكمان عنده مكان في مصر الجديدة'
        }, {
          _id: {
            $oid: '61962be8fd08a800040ab2f4'
          },
          user_name: 'mohamed zidan',
          job: 'sector manager',
          place_address: 'gesr elswees',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '1000904157',
          venue: '000',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Cairo',
          details: 'في الملابس \n01011344929'
        }, {
          _id: {
            $oid: '619633e8fd08a800040ab2f5'
          },
          user_name: 'John gameel',
          job: 'Owner Remex real estate',
          place_address: 'No',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01024593091',
          venue: '000',
          place_to_invest: false,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Cairo',
          details: 'Vip\nقعد مع كابتن مينا'
        }, {
          _id: {
            $oid: '619634a149093e0004b66014'
          },
          user_name: 'Noha samir',
          job: 'Owner شركة مقاولات',
          place_address: 'No',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01111155730',
          venue: '000',
          place_to_invest: false,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Cairo',
          details: 'ملابس اطفال حريمي vip \nقعد مع كابتن مينا'
        }, {
          _id: {
            $oid: '619635e049093e0004b66015'
          },
          user_name: 'Abdelrahaman mahrous ',
          job: 'مدير قطاع الاستثمار ( مجمع عمال مصر )',
          place_address: 'No',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01001570703',
          venue: '00',
          place_to_invest: false,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Giza',
          details: 'اشتراكات للموظفين في المناطق الصناعية بعروض ومميزات مختلفة \nNo details'
        }, {
          _id: {
            $oid: '6196369449093e0004b66016'
          },
          user_name: 'Mohamed farouk',
          job: 'Computer engineer',
          place_address: 'العاصمة الإدارية',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01005214887',
          venue: '750',
          place_to_invest: false,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Cairo',
          details: 'لسة هيشوف مكان'
        }, {
          _id: {
            $oid: '6196370949093e0004b66017'
          },
          user_name: 'Ibrahrem ahmed',
          job: 'Engineer, gym owner',
          place_address: 'الشرقية للأحوال المدنية',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01068862018',
          venue: '500',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Cairo',
          details: ''
        }, {
          _id: {
            $oid: '619637b649093e0004b66018'
          },
          user_name: 'Fahmy nabil',
          job: 'Doctor',
          place_address: 'Different places',
          email: 'ceo@franchise-key',
          mobile_number: '01275707570',
          venue: '000',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Cairo',
          details: 'يعرف يجيب ناس فرنشايز زليه تعامل مع ناس كتير \nNo details\nVip مهم جدا وقعد مع كابتن مينا'
        }, {
          _id: {
            $oid: '619643fc49093e0004b66019'
          },
          user_name: 'Ahmed hassan ',
          job: 'Vompund manager',
          place_address: 'مدينة الشروق،،جراند ',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01050166345',
          venue: '1600',
          place_to_invest: true,
          activity_type: 'Residential',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Alexandria',
          details: 'في مكان في الكومباوند عايزين يفتحوا فيه جيم بس ميكس ده المطلوب هناك اكتر'
        }, {
          _id: {
            $oid: '6196472349093e0004b6601a'
          },
          user_name: '.mahmoud elanany',
          job: 'Projects manager',
          place_address: 'الارضي على شارع رئيسي في شارع المريوطية',
          email: 'mahmoud.eleanny@viral-egypt.com',
          mobile_number: '01064666690',
          venue: '900',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Alexandria',
          details: 'Vip'
        }, {
          _id: {
            $oid: '619647db49093e0004b6601b'
          },
          user_name: 'Dr abdel fattah ',
          job: 'Sport nutrition',
          place_address: 'No',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01147188889',
          venue: '250',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Ismailia',
          details: 'المساحة ماتزيدش عن 250 متر وممكن يبقى كذا دور فوق بعض حسب ما يتفق مع الادارة وممكن يةفر مساحات اكتر'
        }, {
          _id: {
            $oid: '61964a4449093e0004b6601c'
          },
          user_name: 'Mahmoud farouk',
          job: 'Doctor engineer',
          place_address: 'No',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01005214887',
          venue: '00',
          place_to_invest: false,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Cairo',
          details: ' Vip\nAssigned by dr mirna \nReady to sign '
        }, {
          _id: {
            $oid: '61964a9e49093e0004b6601d'
          },
          user_name: 'Moures',
          job: 'Dentist',
          place_address: 'Tagamoa .. masr elgdida',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01220280528',
          venue: '00',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Cairo',
          details: 'متاح يوفر اماكن مختلفة'
        }, {
          _id: {
            $oid: '61964bcf49093e0004b6601e'
          },
          user_name: 'Montaser shabaan،،، emad awad',
          job: 'مدير عام شركة لوج ان ،،،، وكيل شركة انفنكس للموبايلات',
          place_address: 'المقطم',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01200000391',
          venue: '000',
          place_to_invest: false,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Alexandria',
          details: 'كابتن مينا قعد معاهم نلعندهمش مشكلة يجيبوا اي مكان او يدخلوا اي مبلغ\nEmad awad 01200022822\nVip'
        }, {
          _id: {
            $oid: '61964c5f49093e0004b6601f'
          },
          user_name: 'Samir dhahat',
          job: 'Business development manager',
          place_address: 'Ehaasema el edarya ',
          email: 's.shahat@radix-development.com',
          mobile_number: '01010888330',
          venue: '750',
          place_to_invest: false,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Cairo',
          details: ''
        }, {
          _id: {
            $oid: '61965874c5aeec00048f2295'
          },
          user_name: 'Amgad kamal ',
          job: 'Engineer',
          place_address: '713 طريق الحرية لوران',
          email: 'amgad.kamal@yahoo.com',
          mobile_number: '01223434972',
          venue: '1800',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Alexandria',
          details: 'VIP\nبنى مكان في التجمع الخامس واماكن كتير\nحوالي 1800 متر الدور الارضي1000 والاول علوي 800\nثاحب فروع بافلو برجر ف اسكندرية\nوصاحب براند اكل في وسط إسكندرية'
        }, {
          _id: {
            $oid: '61965a00c5aeec00048f2296'
          },
          user_name: 'Ahmed salheen',
          job: 'مدير قطاع ف شركة المهندس',
          place_address: 'Hadayk elahram',
          email: 'nourhanokasha99@gmail.com',
          mobile_number: '01002400930',
          venue: '1000',
          place_to_invest: true,
          activity_type: 'Residential',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Cairo',
          details: 'شركة شحن \nممكن يتحول لتجاري\nVip'
        }, {
          _id: {
            $oid: '619663dac5aeec00048f2297'
          },
          user_name: 'Akran abo samra',
          job: 'Telecommunications engineer',
          place_address: 'Misr elgdida',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01001700778',
          venue: '700',
          place_to_invest: false,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Cairo',
          details: 'عايز يعرف لو دفع 4 مليون نسبته هتكون كام عايز حد من الادارة يكلمه'
        }, {
          _id: {
            $oid: '61966607c5aeec00048f2298'
          },
          user_name: 'Maicheal tomas',
          job: 'Manager',
          place_address: 'Eloubor',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01002225159',
          venue: '800',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Cairo',
          details: 'عمارة 5 ادوار في العبور\nالدورالواحد 800 متر\nممكن يرشحلنا اماكن تانية'
        }, {
          _id: {
            $oid: '619666bdc5aeec00048f2299'
          },
          user_name: 'Sayed ahmed',
          job: 'Engineer',
          place_address: 'Tagamoa',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01289631219',
          venue: '000',
          place_to_invest: false,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Cairo',
          details: 'عاوز يدخل بفلوس او مكان ف التجمع'
        }, {
          _id: {
            $oid: '619677f3c5aeec00048f229a'
          },
          user_name: 'wael mahmoud mohamed',
          job: 'founder',
          place_address: 'elshaikh zayed entrance 2000 - elmohandseen compound ',
          email: 'norhanokaha3@gmail.com',
          mobile_number: '01119191091',
          venue: '400',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Mall',
          franchised_before: false,
          covernate: 'Cairo',
          details: 'vip \nseif 01032231755'
        }, {
          _id: {
            $oid: '6196789fc5aeec00048f229b'
          },
          user_name: 'mohamed elsherbeny',
          job: 'factory of champions - club owner',
          place_address: 'اسكندرية الزراعي',
          email: 'norhanokaha3@gmail.com',
          mobile_number: '1117775185',
          venue: '500',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Alexandria',
          details: 'نادي \nمن 500 ل 700\nعمر الشربيني 01227701424'
        }, {
          _id: {
            $oid: '619679a6c5aeec00048f229c'
          },
          user_name: 'omar salama',
          job: 'marketing manger in mall',
          place_address: 'المحور المركزي  مدخل زايد 2000',
          email: 'norhanokaha3@gmail.com',
          mobile_number: '0106873410',
          venue: '750',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Mall',
          franchised_before: true,
          covernate: 'Cairo',
          details: 'vip'
        }, {
          _id: {
            $oid: '61967ac3c5aeec00048f229d'
          },
          user_name: 'abdalla kareem magdy',
          job: 'deputy sales manager',
          place_address: 'elasema eledarya',
          email: 'norhanokaha3@gmail.com',
          mobile_number: '01050001575',
          venue: '000',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Mall',
          franchised_before: false,
          covernate: 'Cairo',
          details: 'مساحة تشغيلية كبيرة لماكس جم '
        }, {
          _id: {
            $oid: '61969204c5aeec00048f229e'
          },
          user_name: 'Mohamed mamdouh ',
          job: 'civil Engineer',
          place_address: 'Eltagamoa elawal elbanaseg',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01000300330',
          venue: '700',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Mall',
          franchised_before: true,
          covernate: 'Cairo',
          details: 'Wild broker\nVip\n01122999914\nShady 01122227825'
        }, {
          _id: {
            $oid: '619692bfc5aeec00048f229f'
          },
          user_name: 'Mhmoud bahgat',
          job: 'marketing and commercial director',
          place_address: 'Out egypt',
          email: 'contact@legendaryEurpoe.eu',
          mobile_number: '00966568654916',
          venue: '000',
          place_to_invest: false,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Cairo',
          details: 'Vip'
        }, {
          _id: {
            $oid: '6196937bc5aeec00048f22a0'
          },
          user_name: 'Mohamed Ibrahim',
          job: 'Business man',
          place_address: 'Sheraton street.',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01143491872',
          venue: '800',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Hurghada',
          details: ''
        }, {
          _id: {
            $oid: '619696e2c5aeec00048f22a1'
          },
          user_name: 'sherief aly',
          job: 'business man',
          place_address: 'tagamoa',
          email: 'norhanokaha3@gmail.com',
          mobile_number: '01066699813',
          venue: '000',
          place_to_invest: false,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Cairo',
          details: 'خل قبل كده فرنشايز في التكنولوجيا وحابب يفتح مكان في التجمع'
        }, {
          _id: {
            $oid: '619697d8c5aeec00048f22a2'
          },
          user_name: 'ahmed ali abd elhameed',
          job: 'business man',
          place_address: 'no',
          email: 'norhanokaha3@gmail.com',
          mobile_number: '01016705755',
          venue: '750',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Mall',
          franchised_before: false,
          covernate: 'Beni Suef',
          details: 'vip\nplace under construction'
        }, {
          _id: {
            $oid: '6196982cc5aeec00048f22a3'
          },
          user_name: 'mohamed essam',
          job: 'operating manager ',
          place_address: 'eloubor el mantiqa el khamesa',
          email: 'norhanokaha3@gmail.com',
          mobile_number: '01011180911',
          venue: '700',
          place_to_invest: true,
          activity_type: 'Residential',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Cairo',
          details: 'vip'
        }, {
          _id: {
            $oid: '619698a2c5aeec00048f22a4'
          },
          user_name: 'hazem soulan',
          job: 'طبيب بطري',
          place_address: 'طريق هرة اسندرية الزراعي امام مدخل كفر الشيخ',
          email: 'norhanokaha3@gmail.com',
          mobile_number: '01001640865',
          venue: '600',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Tanta',
          details: ''
        }, {
          _id: {
            $oid: '61969981c5aeec00048f22a5'
          },
          user_name: 'mohamed abd elraheem',
          job: 'sales dirctor',
          place_address: 'elasema eledarya',
          email: 'norhanokaha3@gmail.com',
          mobile_number: '01220254125',
          venue: '000',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Cairo',
          details: 'المساحة حسب الاحتياج'
        }, {
          _id: {
            $oid: '619699fdc5aeec00048f22a6'
          },
          user_name: 'tarek mohamed ',
          job: 'pharmacist ',
          place_address: 'عمارة بنك مصر شارع 9 - المقطم عبى الشارع الرئيسي',
          email: 'norhanokaha3@gmail.com',
          mobile_number: '01001038265',
          venue: '500',
          place_to_invest: true,
          activity_type: 'Administrative',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Cairo',
          details: 'شركاء مجموعة 3.5 % - تم التصالح فاضل نموذج 10'
        }, {
          _id: {
            $oid: '61969af9c5aeec00048f22a7'
          },
          user_name: 'Mahmoud ',
          job: 'Engineer',
          place_address: 'No',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01022639497',
          venue: '1000',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Mansoura',
          details: 'استثمر قبل كده ف المطاعم\nالمساحة من 1000 ل 3000'
        }, {
          _id: {
            $oid: '6196a394fec9f3000483b05b'
          },
          user_name: 'Mahmoud elgaml',
          job: 'No',
          place_address: 'No',
          email: 'norhanokasha3@gmail.com',
          mobile_number: '01001817273',
          venue: '00',
          place_to_invest: false,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: true,
          covernate: 'Cairo',
          details: 'عنده فرنشايز كريستال عصفور في المعادق وعاوز يتوسع بجيم ويعرف تفاصيل رقمية'
        }, {
          _id: {
            $oid: '61ccb20aa43d000004437e70'
          },
          user_name: 'Mohamed',
          job: 'Engineer',
          place_address: 'Talkha',
          email: 'harrazgroup@yahoo.com',
          mobile_number: '01112184240',
          venue: '390',
          place_to_invest: true,
          activity_type: 'Commercial',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Mansoura',
          details: "I have a space of 390 m2 in Talkha in Al Bahr Al aazam street. I'd like to know if you are interested in opening a branch there."
        }, {
          _id: {
            $oid: '61ebc4fa9931ca0004e75178'
          },
          user_name: 'Aya lawash',
          job: 'Pharmacist and nutritionist ',
          place_address: 'Portsaid',
          email: 'aya.lawash@gmail.com',
          mobile_number: '01126559533',
          venue: '01126559533',
          place_to_invest: true,
          activity_type: 'Residential',
          building_type: 'Building',
          franchised_before: false,
          covernate: 'Port Said',
          details: ''
        }
        ],
      field: [
        { label: 'User Name', key: 'user_name', class: 'text-left', sortable: true },
        { label: 'Job', key: 'job', class: 'text-left', sortable: true },
        { label: 'Place Address', key: 'place_address', class: 'text-left', sortable: true },
        { label: 'Email', key: 'email', class: 'text-left', sortable: true },
        { label: 'Mobile Number', key: 'mobile_number', class: 'text-left', sortable: true },
        { label: 'Venue', key: 'venue', class: 'text-left', sortable: true },
        { label: 'Place To Invest', key: 'place_to_invest', class: 'text-left', sortable: true },
        { label: 'Activity Type', key: 'activity_type', class: 'text-left', sortable: true },
        { label: 'Building Type', key: 'building_type', class: 'text-left', sortable: true },
        { label: 'Franchised Before', key: 'franchised_before', class: 'text-left', sortable: true },
        { label: 'Covernate', key: 'covernate', class: 'text-left', sortable: true },
        { label: 'Details', key: 'details', class: 'text-left' }]
    }
  }
}
</script>
<style>
.vue-daterange-picker{
  display: block !important;
}
.form-control.reportrange-text {
  border: 1px solid var(--iq-primary) !important;
  border-radius: 10px !important;
  color: var(--iq-primary) !important;
  margin: 10px 0 !important;
  height: auto !important;
  line-height: 30px !important;
  background: #fff !important;
}
</style>
